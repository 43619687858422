/* eslint-disable camelcase */
import React from 'react'
import Typography from '../components/Typography'
import Link from '../components/Link'

export const geoLocations = [
  'California',
  'Delaware',
  'Illinois',
  'Indiana',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'New Jersey',
  'New York',
  'Ohio',
  'Georgia',
  'Texas',
  'Alberta',
  'British Columbia',
  'Ontario',
  'Quebec',
  'Saskatchewan',
  'Manitoba',
]

const common = [
  {
    title: 'Will my bill come directly from Just Energy?',
    content: (
      <Typography>
        In most cases, Just Energy does not bill separately from your utility
        for electricity or natural gas. Just Energy&apos;s charges will appear
        as a separate line item on your utility bill. Only customers in Texas,
        Georgia, California, and Alberta receive their bill directly from Just
        Energy.
      </Typography>
    ),
  },
  {
    title: 'Is Just Energy charging me for my supply only?',
    content: (
      <Typography>
        Your contract with Just Energy covers your electricity and/or natural
        gas supply costs and JustGreen if you choose to enroll. If you choose to
        enroll in additional contracts, such an ecobee payment plan, you may see
        these charges on your bill as well. If your electricity and/or natural
        gas bill does not come directly from Just Energy, but from your local
        utility provider, you’ll receive a separate bill for your additional
        product contract from Just Energy.
      </Typography>
    ),
  },
  {
    title: 'Who do I contact with questions regarding my bill?',
    content: (
      <Typography>
        Refer to your plan’s Terms and Conditions to learn more about your
        contract. If you live in Texas or Alberta, most questions regarding your
        bill can be answered simply by logging in to your Just Energy{' '}
        <a href="https://www.justenergy.com/myaccount">online account</a>. You
        can also sign in to the Just Energy Advisor app to check your current
        account status, billing history, current charges, and other details.
        Need to speak a customer care agent directly? Call{' '}
        <a href="tel:1-866-587-8674">1-866-587-8674</a> to speak with someone
        who can help you with your account.
      </Typography>
    ),
  },
  {
    title: 'How can I cancel my Just Energy contract?',
    content: (
      <Typography>
        You can contact our customer service team to cancel your contract, but
        note that early termination fees may apply. <br />
        <br />
        Just Energy Customer Service:{' '}
        <a href="tel:1-877-240-3931">1-877-240-3931</a>
        <br />
        Amigo Energy Customer Service:{' '}
        <a href="tel:1-844-344-6991">1-844-344-6991</a>
        <br />
        Tara Energy Customer Service:{' '}
        <a href="tel:1-844-344-6992">1-844-344-6992</a>
      </Typography>
    ),
  },
]

const tx_ga_ab = [
  {
    title: 'Where and how do I pay my energy bill?',
    content: (
      <Typography>
        You will receive your bill and can render payment directly with Just
        Energy. Consult your bill for payment options or check out our{' '}
        <Link to="/help">Payments page</Link> for more information.
      </Typography>
    ),
  },
]

// Same faq as the above with a different answer for all other states/providences
const everyoneElse = [
  {
    title: 'Where and how do I pay my energy bill?',
    content: (
      <Typography>
        Just Energy does not bill separately from your utility bill in most
        states and territories, it is simply listed as your supplier. Contact
        your utility to determine what methods can be used to pay your bill.
      </Typography>
    ),
  },
]

export const geoTabData = {
  California: [...everyoneElse, ...common],
  Delaware: [...everyoneElse, ...common],
  Illinois: [...everyoneElse, ...common],
  Indiana: [...everyoneElse, ...common],
  Maryland: [...everyoneElse, ...common],
  Massachusetts: [...everyoneElse, ...common],
  Michigan: [...everyoneElse, ...common],
  New_Jersey: [...everyoneElse, ...common],
  New_York: [...everyoneElse, ...common],
  Ohio: [...everyoneElse, ...common],
  Pennsylvania: [...everyoneElse, ...common],
  Georgia: [...tx_ga_ab, ...common],
  Texas: [...tx_ga_ab, ...common],
  Alberta: [...tx_ga_ab, ...common],
  British_Columbia: [...everyoneElse, ...common],
  Ontario: [...everyoneElse, ...common],
  Quebec: [...everyoneElse, ...common],
  Saskatchewan: [...everyoneElse, ...common],
  Manitoba: [...everyoneElse, ...common],
}
