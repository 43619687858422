import React from 'react'
import PropTypes from 'prop-types'

import * as Styled from './VariableContent.styles'
import Section from '../Section'
import Img from '../Image'
import Typography from '../Typography'

const VariableContent = ({
  heading,
  subheading,
  mainContent,
  image,
  children,
  alignImageToBottom,
  centeredContent,
  ...props
}) => (
  <Section {...props}>
    <Styled.Intro
      hasChildren={Boolean(children && heading)}
      centeredContent={centeredContent}
    >
      {image && !alignImageToBottom && <Img data={image} />}
      {heading && <Typography variant="h2">{heading}</Typography>}
      {subheading && (
        <Typography variant="h5" rendersAs="h3">
          {subheading}
        </Typography>
      )}
      <Styled.Content centeredContent={centeredContent}>
        {mainContent}
      </Styled.Content>
      {image && alignImageToBottom && <Img data={image} />}
    </Styled.Intro>
    {children}
  </Section>
)

VariableContent.defaultProps = {
  subheading: '',
  mainContent: null,
  children: null,
  image: null,
  alignImageToBottom: false,
  centeredContent: false,
}

VariableContent.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.object,
  }),
  alignImageToBottom: PropTypes.bool,
  centeredContent: PropTypes.bool,
  mainContent: PropTypes.shape({ json: PropTypes.object }),
  children: PropTypes.node,
}

export default VariableContent
