import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import '@reach/menu-button/styles.css'
import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button'
import Link from '../components/Link'
import Section from '../components/Section'
import VariableContent from '../components/VariableContent'
import Typography from '../components/Typography'
import Columns from '../components/Columns'
import Column from '../components/Columns/Column'
import Button from '../components/Button'

import Hero from '../components/Hero'
import Tabs from '../components/Tabs'
import Layout from '../layouts'
import dropdown from '../images/dropdown-blue.svg'

import { geoLocations, geoTabData } from '../data/customer-service'

import iconDollars from '../images/icon-dollars.svg'
import iconSpeechBubbles from '../images/icon-speechbubbles.svg'
import iconCustomer from '../images/icon-customer.svg'
import iconCustomerService from '../images/icon-customerservice.svg'

const StyledMenuButton = styled(MenuButton)`
  padding: ${({ theme }) =>
    `${theme.spacing.xxs} ${theme.spacing.xl} ${theme.spacing.xxs} ${theme.spacing.xs}`};
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme }) => theme.colors.light.lighter.hex};
  min-width: 272px;
  border: 0;
  background-image: url(${dropdown});
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: right;
  text-align: left;

  span {
    display: none;
  }
`

const Icon = styled.img`
  height: 100px;
  width: 100px;
  margin-left: 0;
`

const StyledHero = styled(Hero)`
  margin-bottom: 75px;
`

const HelpPage = () => {
  const [location, setLocation] = useState('')

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Hero-Green-Energy.jpg" }) {
        extension
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>Customer Service</title>
        <meta name="description" content="" />
      </Helmet>
      <StyledHero
        image={{
          childImageSharp: data.file.childImageSharp,
          description: 'Green gradient background',
        }}
        darkText={false}
      >
        <Typography variant="h1" rendersAs="h1">
          FAQs {location && <span>for {location.replace(/_/, ' ') || ''}</span>}
        </Typography>
        <Menu>
          <StyledMenuButton>
            {location.replace(/_/, ' ') || 'Select a Location'}{' '}
            <span aria-hidden>▾</span>
          </StyledMenuButton>
          <MenuList>
            {geoLocations.map(x => (
              <MenuItem
                key={x}
                onSelect={() => setLocation(x.replace(/ /, '_'))}
              >
                {x.replace(/_/, ' ')}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </StyledHero>
      <Section>
        {!location && (
          <Typography variant="h2">
            Select a location above to see FAQs for that region.
          </Typography>
        )}
        {location && location !== 'Select a Location' && (
          <Tabs isVertical data={geoTabData[location]} />
        )}
      </Section>

      <VariableContent hasLargeContent={false} heading="How can we help you?">
        <Columns cardStyled>
          <Column>
            <Link to="/customer-service/faq/billing-payment">
              <Icon src={iconDollars} alt="Icon of a a dollar bill" />
              <Typography variant="h5">Billing and Payment</Typography>
            </Link>
          </Column>
          <Column>
            <Link to="/customer-service/faq/">
              <Icon src={iconCustomerService} alt="Icon of a headset" />
              <Typography variant="h5">FAQs</Typography>
            </Link>
          </Column>
          <Column>
            <Link to="/report-an-outage/">
              <Icon src={iconSpeechBubbles} alt="Icon of speech bubbles" />
              <Typography variant="h5">Report an Outage</Typography>
            </Link>
          </Column>
          <Column>
            <Link to="/contact-us/">
              <Icon src={iconCustomer} alt="Icon of a customer" />
              <Typography variant="h5">Contact Us</Typography>
            </Link>
          </Column>
        </Columns>
      </VariableContent>

      <VariableContent
        hasLargeContent={false}
        heading="Didn't find what you're looking for?"
        mainContent={
          <Link to="/contact-us/">
            <Button variant="feature">Contact Us</Button>
          </Link>
        }
      />
    </Layout>
  )
}

export default HelpPage
